<style lang="sass">

</style>

<template>
  <div style="height: 100vh">
    <top-bar />
    <iframe
      style="height: 90vh; width: 100%"
      src="https://app.powerbi.com/reportEmbed?reportId=9168c473-5ddb-4b72-a9c8-0164bdeddd80&autoAuth=true&ctid=1bcd12a8-3150-4dac-9b28-22fa6d053198&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
    
}
</script>